<template>
<!-- 专家回答问题 -->
  <div class="container">
    <div class="form">
      <div class="row start" style="margin: 12px 0">
        <div class="label">问题名称</div>
        <van-field v-model="form.title" rows="5" disabled autosize type="textarea" />
        <!-- <div class="read">{{ form.content }}</div> -->
      </div>

      <div class="row start" style="margin-bottom: 12px">
        <div class="label">问题回答</div>
        <div v-if="form.replyNum===0">暂无</div>
        <div v-else style="width: 100%">
          <div class="alist" v-for="(item, index) in list" :key="index">
            <div class="user">
              <span class="name">{{ item.createUserName }}</span>
              <span class="time">{{ item.createTime }}</span>
            </div>
            <div class="item">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <van-button v-if="form.replyNum!==0" type="info" block @click="goAppraise">{{ form.isGrade !== 1?'去评价':'查看评价' }}</van-button>
  </div>
</template>
<script>
import { getAskDetail, getAnswerDetailList } from '@/api/eventApi'
export default {
  data() {
    return {
        form: {
          title: '',
          content: '',
        },
        type: '',
        list: []
    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.getDetail(this.$route.query.id)
    this.getList(this.$route.query.id)
  },
  mounted() {},
  methods: {
    getDetail(id) {
      getAskDetail({
        id: id
      }).then(res => {
        this.form.title = res.data.title
        this.form.content = res.data.content
        this.form.isGrade = res.data.isGrade
        this.form.replyNum = res.data.replyNum
      })
    },
    getList(id) {
      this.$store.commit('SET_IS_LOADING', true)
      getAnswerDetailList({
        noticeId: id
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        this.list = res.data.records
      })
    },
    goAppraise() { 
      this.$router.push({
        path: `/appraise?gradeType=0&id=${this.form.id}`
      }) 
    }
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
}
.row {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  &.row2 {
    align-items: center;
    .label {
      width: 65px;
    }
    .van-field {
      padding: 2px 4px;
    }
  }
  &.col {
    flex-direction: column;
    align-items: start;
    .van-field {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      /* height: 100px; */
    }
    .label {
      margin-bottom: 10px;
    }
  }
  .label {
    flex-shrink: 0;
    width: 65px;
  }
  .label.must {
    position: relative;
  }
  .label.must::after {
    content: '*';
    color: #e10602;
    font-size: 18px;
    position: absolute;
    left: -8px;
    top: 1px;
  }
}
.alist {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #fff;

  .user {
    border-bottom: 1px solid #eeeeee;
    color: #c8c9cc;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  .name {
    margin-right: 24px;
    font-size: 14px;
    font-weight: 700;
  }
  .time {
    font-size: 12px;
    // color: #969799;
  }
  .item {
    width: 100%;
    color: #c8c9cc;
    padding-top: 5px;
  }
}
.read {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #eeeeee;
}
.alist {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 10px;
  box-sizing: border-box;
}
.form {
  min-height: calc(100vh - 24px - 46px - 56px);
}
.row.start {
  align-items: start;
}
::v-deep .row .van-uploader__upload {
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 5px;
}
.van-field {
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 5px;
}
</style>
